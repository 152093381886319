<template>
    <div class="contract-preview">
        <div class="contract-preview-header">
            <div>合同预览</div>
        </div>

        <div class="contract-preview-item first">
            <div class="item-field">
                <div class="field-name">合同编号</div>
                <div class="field-content">{{ contractInfo.contractNo }}</div>
            </div>
            <div class="item-field" v-if="!isEditPreview">
                <div class="field-name">合同创建时间</div>
                <div class="file-content">{{ contractInfo.createTime }}</div>
            </div>
            <div class="item-field">
                <div class="field-name">合同开始时间</div>
                <div class="file-content">{{ contractInfo.startDate }}</div>
            </div>
            <div class="item-field">
                <div class="field-name">合同截止时间</div>
                <div class="file-content">{{ contractInfo.endDate }}</div>
            </div>
            <template v-if="isEditPreview">
                <button
                    class="btn btn-primary btn-step"
                    @click="changeContractStep(1)"
                >
                    修改
                </button>
            </template>
        </div>
        <div class="contract-preview-item">
            <div class="item-field">
                <div class="field-name">租赁时间</div>
                <div class="field-content">
                    {{
                        getContractDay(contractInfo.startDate, contractInfo.endDate)
                    }}
                </div>
            </div>
            <div class="item-field">
                <div class="field-name">房间租金</div>
                <div class="field-content">
                    <template v-if="contractInfo.rent">
                        {{ contractInfo.rent }}元
                    </template>
                    <template v-else-if="contractInfo.rentAmount">
                        {{ contractInfo.rentAmount }} 元
                    </template>
                    <template v-else>-</template>
                </div>
            </div>
            <div class="item-field">
                <div class="field-name">合同金额</div>
                <div class="field-content">
                    <div
                        style="display: block"
                        v-for="(
                            installment, index
                        ) in contractInfo.installments"
                        :key="`${installment.paymentDate}_${index}`"
                    >
                        <p>
                            支付日期： {{ installment.paymentDate }}
                            <span style="margin-left: 20px">
                                支付金额：
                                {{ installment.amount }}元</span
                            >
                        </p>
                        <template v-if="installment.deduction">
                            <p>
                                <span class="rightMargin_">
                                    抵扣金额:{{
                                        installment.deductionAmount
                                    }}元
                                </span>
                                <span
                                >抵扣说明:
                                    {{ installment.comment || '-' }}</span
                                >
                            </p>
                        </template>
                    </div>
                </div>

            </div>
            <div class="item-field">
                <div class="field-name">房间押金</div>
                <div class="field-content">
                    <div>
                        支付日期：
                        {{
                            contractInfo.depositPaymentTime ||
                            contractInfo.depositPaymentDate
                        }}
                        <span style="margin-left: 19px">
                            支付金额： {{ contractInfo.deposit }} 元</span
                        >
                    </div>
                </div>
            </div>
            <template v-if="contractInfo.otherCharges && contractInfo.otherCharges.length > 0">
                <div
                    class="item-field"
                    v-for="charge in contractInfo.otherCharges"
                    :key="charge.name"
                >
                    <div
                        style="
                        width: 120px !important;
                        text-align: right;
                        padding-top: 2px;
                    "
                    >
                        {{ charge.name }}
                    </div>

                    <div
                        class="field-content"
                        style="width: 410px; margin-left: 60px"
                    >
                        <div
                            class="mb-1"
                            style="display: block"
                            v-for="(installment, index) in charge.installments"
                            :key="`${installment.paymentDate}_${index}`"
                        >
                            <p>
                            <span class="rightMargin_"
                            >支付日期：{{ installment.paymentDate }}</span
                            >
                                <span
                                >支付金额：{{
                                        installment.amount
                                    }}
                                元</span
                                >
                            </p>
                            <template v-if="installment.deduction">
                                <p>
                                    抵扣金额:
                                    {{ installment.deductionAmount }} 元
                                    抵扣说明:
                                    {{ installment.comment || '-' }}
                                </p>
                            </template>
                        </div>
                    </div>
                </div>
            </template>

            <template v-if="isEditPreview">
                <button
                    class="btn btn-primary btn-step"
                    @click="changeContractStep(2)"
                >
                    修改
                </button>
            </template>
        </div>

        <template
            v-if="
                !isEditPreview || contractInfo.otherCharges &&
                contractInfo.otherCharges.length > 0
            "
        >
            <div class="contract-preview-item">
                <div class="item-field">
                    <div class="field-name">租客</div>
                    <div class="field-content">
                        {{ contractInfo.lesseeCompany }}
                    </div>
                </div>
                <div class="item-field">
                    <div class="field-name" style="line-height: 1;">房间</div>
                    <div class="field-content room-field">
                        <div
                            v-for="(room, rIndex) in rooms"
                            :key="rIndex"
                        >
                            <p class="mb-2">
                                {{
                                    room.roomName ? room.buildingName + room.roomName : `${room.buildingName}-${room.roomNo}`
                                }}
                            </p>
                            <div
                                v-if="
                                    (room.photosBeforeSettle &&
                                        room.photosBeforeSettle.length > 0) ||
                                    !readonly
                                "
                            >
                                <p class="mb-2">入驻前照片</p>
                                <div v-for="photoItem in room.photosBeforeSettle"
                                     :key="photoItem.id" class="room-photo mb-2">
                                    <svg aria-hidden="true" class="icon" @click="deletePhotos(photoItem.id)">
                                        <use xlink:href="#icon-menushanchu"></use>
                                    </svg>
                                    <img :src="photoItem.content" />
                                </div>
                                <template v-if="!readonly">
                                    <div
                                        class="upload-room-photo"
                                        @click="
                                            arouseUploadRoom(
                                                room.id,
                                                UploadRoomPhotoType.BEFORE,
                                                'uploadRoomPhoto'
                                            )
                                        "
                                    >
                                        <div>
                                            <img
                                                src="../assets/upload.png"
                                                alt=""
                                                style="margin-bottom: 5px;"
                                            />
                                            <p style="color: #999">上传照片</p>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div
                                style="display: block"
                                v-if="
                                    (room.photosAfterSettle &&
                                        room.photosAfterSettle.length > 0) ||
                                    !readonly
                                "
                            >
                                <p class="mt-2 mb-2">入驻后照片</p>
                                <div v-for="photoItem in room.photosAfterSettle"
                                     :key="photoItem.id" class="room-photo mb-2">
                                    <svg aria-hidden="true" class="icon" @click="deletePhotos(photoItem.id)">
                                        <use xlink:href="#icon-menushanchu"></use>
                                    </svg>
                                    <img :src="photoItem.content" />
                                </div>

                                <template v-if="!readonly">
                                    <div
                                        class="upload-room-photo"
                                        @click="
                                            arouseUploadRoom(
                                                room.id,
                                                UploadRoomPhotoType.AFTER,
                                                'uploadRoomPhoto'
                                            )
                                        "
                                    >
                                        <div>
                                            <img
                                                src="../assets/upload.png"
                                                alt=""
                                                style="margin-bottom: 5px;"
                                            />
                                            <p style="color: #999">上传照片</p>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <template v-if="!readonly">
                            <input
                                type="file"
                                class="file"
                                accept="images/*"
                                id="uploadRoomPhoto"
                                hidden
                                @change="choosePhoto($event)"
                            />
                        </template>
                    </div>
                </div>

                <template v-if="isEditPreview">
                    <button
                        class="btn btn-primary btn-step"
                        @click="changeContractStep(4)"
                    >
                        修改
                    </button>
                </template>
            </div>
        </template>

        <div class="contract-preview-item">
            <div class="item-field">
                <div class="field-name">附件</div>
                <div class="field-content">
                    <div
                        v-for="(attachment, aIndex) in attachments"
                        style="display: block; line-height: 30px;"
                        :key="aIndex + 'attachment'"
                    >
                        <div v-if="isEditPreview"
                             style="width: 500px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">
                            {{
                                attachment.content.split("/")[
                                attachment.content.split("/").length - 1
                                    ]
                            }}
                        </div>

                        <template v-else>
                            <p style="display:inline-block; vertical-align: middle; width: 300px; white-space:nowrap; overflow:hidden; text-overflow:ellipsis;">
                                {{ attachment.content.split("/")[attachment.content.split("/").length - 1] }}
                            </p>
                            <template
                                v-if="
                                    [
                                        'png',
                                        'jpg',
                                        'jpeg',
                                        'gif',
                                        'webp',
                                    ].includes(
                                        attachment.content.split('.')[
                                            attachment.content.split('.').length - 1
                                        ]
                                    )
                                "
                            >
                                <span
                                    class="allow-click"
                                    style="font-size: 20px;margin-right: 10px;"
                                    @click="lookAttachment(attachment.content)"
                                >
                                    查看
                                </span>
                            </template>
                        </template>

                        <span
                            class="allow-click"
                            style="font-size: 20px"
                            @click="downloadFile(attachment.content)"
                        >
                            下载
                        </span>
                        <!-- <span
                            class="allow-click"
                            style="font-size: 20px"
                            @click="downloadFile(attachment)"
                        >
                            删除
                        </span> -->
                    </div>
                    <p v-if="!readonly">
                        <span
                            class="btn btn-link"
                            style="font-size: 20px; padding-top: 0"
                            @click="arouseChoosePhoto('uploadAttachmentPhoto')"
                        >上传文件</span
                        >
                        <span
                            slot="tip"
                            class="el-upload__tip"
                            style="font-size: 20px"
                        >
                           <svg class="icon" aria-hidden="true">
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            可上传电子合同、合同照片。
                        </span
                        >
                        <input
                            type="file"
                            class="file"
                            accept="images/*"
                            id="uploadAttachmentPhoto"
                            hidden
                            @change="choosePhoto($event)"
                        />
                    </p>
                </div>
            </div>

            <div class="item-field">
                <div class="field-name">备注</div>
                <div class="field-content">
                    {{ contractInfo.comment || '-' }}
                </div>
            </div>
            <template v-if="isEditPreview">
                <button
                    class="btn btn-primary btn-step"
                    @click="changeContractStep(4)"
                >
                    修改
                </button>
            </template>
        </div>
    </div>
</template>
<script>
import {
    queryContractRoomPictureUrl,
    addContractAttachmentsUrl, deleteContractRoomPictureUrl,
} from "@/requestUrl";
import {UploadRoomPhotoType} from "../../common/commonConst";
import dayjs from "dayjs";

export default {
    name: "ContractPreview",
    props: {
        contractInfo: Object,
        // 是否可以编辑(合同表单提交时预览)
        isEditPreview: {
            type: Boolean,
            defaule: false,
        },
        // 是否是只读
        readonly: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            rooms: [],
            attachments: [],
            UploadRoomPhotoType,
            contractDeatil: {},
            uploadRoomId: "",
            uploadRoomType: "",
        };
    },
    watch: {
        contractInfo: {
            handler: function (contract) {
                this.rooms = contract.rooms;
                this.attachments = contract.attachments;
            },
            deep: true,
        },
    },
    mounted() {
        this.rooms = this.contractInfo.rooms;
        this.attachments = this.contractInfo.attachments;
    },
    methods: {
        getContractDay(startDate, endDate) {
            return dayjs(endDate).diff(dayjs(startDate), 'day') + '天';
        },
        /**
         * 下载文件（仅限阿里oss的文件）
         * @param {String} fileSrc 文件的资源地址
         *  */
        downloadFile(fileSrc) {
            window.open(
                fileSrc.replace(
                    "https://alioss.techops.cn/",
                    "https://cosmoss-img.oss-cn-beijing.aliyuncs.com/"
                )
            );
        },
        /**
         * 查看附件
         * @param {String} src 附件地址
         *  */
        lookAttachment(src) {
            this.$vc.emit(this.$route.path, "viewImageModal", "view", {photos: src});
        },
        arouseChoosePhoto(el) {
            $("#" + el).trigger("click");
        },
        async uploadPhoto(base64) {
            return new Promise((reslove, reject) => {
                this.$vc.http.post(
                    "uploadImg",
                    "upload",
                    {
                        img: base64,
                    },
                    {
                        headres: {
                            "Content-Type": "application/json",
                        },
                    },
                    (resStr, res) => {
                        reslove(res.body.fileSaveName);
                    },
                    (errText, err) => {
                        reject(errText);
                    }
                );
            });
        },
        choosePhoto(event) {
            var photoFiles = event.target.files;
            if (photoFiles && photoFiles.length > 0) {
                // 获取目前上传的文件
                var file = photoFiles[0]; // 文件大小校验的动作
                if (file.size > 1024 * 1024 * 2) {
                    this.$vc.toast("图片大小不能超过 2MB!");
                    return false;
                }
                var reader = new FileReader(); //新建FileReader对象
                reader.readAsDataURL(file); //读取为base64
                reader.onloadend = async (e) => {
                    const photo = await this.uploadPhoto(reader.result).catch(
                        (err) => {
                            console.error("上传照片失败");
                            return "";
                        }
                    );
                    if (event.target.id === "uploadAttachmentPhoto") {
                        this.addContractAttachments(photo);
                    } else {
                        this.addContractRoomPicture(photo);
                    }
                };
                event.target.value = '';
            }
        },
        /**
         * 添加合同附件
         * @param {String} url 上传后的网络资源地址
         *  */
        addContractAttachments(url) {
            this.$fly
                .post(addContractAttachmentsUrl, {
                    contractId: this.contractInfo.id,
                    operatorId: this.$vc.getCurrentStaffInfo().userId,
                    content: url,
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    this.attachments.push(url);
                });
        },
        /**
         * 启动上传房间照片
         * @param {Number} id 房间id
         * @param {Number} type 1：入驻前   2：入驻后
         * @param {String} el 元素id名称
         *  */
        arouseUploadRoom(id, type, el) {
            this.uploadRoomId = id;
            this.uploadRoomType = type;
            this.arouseChoosePhoto(el);
        },
        /**
         * 删除房间照片
         * @param {Number} id 照片id
         * */
        deletePhotos(id) {
            this.$fly.get(deleteContractRoomPictureUrl, {id})
                .then(res => {
                    if (res.code === 0)
                        this.$emit('refreshContractData', this.contractInfo.id);
                })
        },
        /**
         * 添加房间照片
         * @param {String} url 上传后的网络资源地址
         *  */
        addContractRoomPicture(url) {
            this.$fly
                .post(queryContractRoomPictureUrl, {
                    contractId: this.contractInfo.id,
                    operatorId: this.$vc.getCurrentStaffInfo().userId,
                    content: url,
                    roomId: this.uploadRoomId,
                    type: this.uploadRoomType,
                })
                .then((res) => {
                    if (res.code != 0) {
                        return;
                    }
                    this.$emit('refreshContractData', this.contractInfo.id);
                });
        },
        // 修改合同步骤
        changeContractStep(step) {
            this.$emit("change-contract-step", step);
        },
    },
};
</script>

<style>
/*合同预览*/
.contract-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 99%;
    height: 698px;
    background: #fff;
    overflow-y: auto;
    box-sizing: border-box;
    z-index: 3;
    color: #000000;
    border-radius: 10px;
}

.contract-preview p {
    margin-bottom: 0;
}

.contract-preview-header div {
    padding: 20px 40px;
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    border-bottom: 1px solid #dddddd;

}

.contract-preview-item {
    padding: 20px 40px;
    border-top: 1px solid #dddddd;
    font-size: 20px;
    position: relative;
    margin: 0 40px;
}

.first {
    border-top: none;
}

.contract-preview-item .btn-step {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 160px;
    height: 40px;
    background: #1ab394;
    box-shadow: 0 5px 10px 0 rgba(0, 182, 148, 0.3);
    border-radius: 4px;
    font-size: 20px;
}

.contract-preview-item .item-field div {
    display: inline-block;
    vertical-align: top;
}

.contract-preview-item .item-field:not(:last-of-type) {
    margin-bottom: 20px;
}

.contract-preview-item .item-field .field-name {
    margin-right: 60px;
    text-align: right;
    width: 120px;
}

.contract-preview-item .item-field .field-content {
    max-width: calc(100% - 120px - 60px);
}

.upload-room-photo,
.room-photo {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    position: relative;
    display: inline-block !important;
}

.room-photo img {
    width: 100%;
    height: 100%;
}

.room-photo {
    margin-right: 10px;
}

.room-photo .icon {
    position: absolute;
    right: 0;
    top: 0;
    font-size: 14px;
    transform: translate(50%, -50%);
}

.upload-room-photo {
    text-align: center;
    border: 1px dashed #999999;
    font-size: 14px;
    position: relative;
}

.upload-room-photo div {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
}

.upload-room-photo img {
    width: 34px;
    height: 27px;
    margin-bottom: 13px;
}

.rightMargin_ {
    margin-right: 30px;
}

.leftsize {
    width: 120px !important;
    text-align: right;
}

.btn-link {
    padding-left: 0;
    font-size: 24px;
}

.contract-preview-item div.room-field div {
    display: block;
    line-height: 1;
}

.room-field > div:not(:last-of-type) {
    margin-bottom: 30px;
}

.contract-preview-item div.room-field .upload-room-photo {
    display: inline-block;
}

.room-field > div > p:not(:last-of-type) {
    margin-bottom: 20px;
}

</style>
